[class*=' socicon-']:before,
[class^='socicon-']:before,
[data-icon]:before {
    font-family: socicon !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: socicon;
    src: url(./socicon.eot);
    src: url(./socicon.eot?#iefix) format('embedded-opentype'),
    url(./socicon.woff) format('woff'),
    url(./socicon.ttf) format('truetype'),
    url(./socicon.svg#socicon) format('svg');
    font-weight: 400;
    font-style: normal;
}
[data-icon]:before {
    content: attr(data-icon);
}

.socicon-modelmayhem:before {
    content: '\e000';
}
.socicon-mixcloud:before {
    content: '\e001';
}
.socicon-drupal:before {
    content: '\e002';
}
.socicon-swarm:before {
    content: '\e003';
}
.socicon-istock:before {
    content: '\e004';
}
.socicon-yammer:before {
    content: '\e005';
}
.socicon-ello:before {
    content: '\e006';
}
.socicon-stackoverflow:before {
    content: '\e007';
}
.socicon-persona:before {
    content: '\e008';
}
.socicon-triplej:before {
    content: '\e009';
}
.socicon-houzz:before {
    content: '\e00a';
}
.socicon-rss:before {
    content: '\e00b';
}
.socicon-paypal:before {
    content: '\e00c';
}
.socicon-odnoklassniki:before {
    content: '\e00d';
}
.socicon-airbnb:before {
    content: '\e00e';
}
.socicon-periscope:before {
    content: '\e00f';
}
.socicon-outlook:before {
    content: '\e010';
}
.socicon-coderwall:before {
    content: '\e011';
}
.socicon-tripadvisor:before {
    content: '\e012';
}
.socicon-appnet:before {
    content: '\e013';
}
.socicon-goodreads:before {
    content: '\e014';
}
.socicon-tripit:before {
    content: '\e015';
}
.socicon-lanyrd:before {
    content: '\e016';
}
.socicon-slideshare:before {
    content: '\e017';
}
.socicon-buffer:before {
    content: '\e018';
}
.socicon-disqus:before {
    content: '\e019';
}
.socicon-vkontakte:before {
    content: '\e01a';
}
.socicon-whatsapp:before {
    content: '\e01b';
}
.socicon-patreon:before {
    content: '\e01c';
}
.socicon-storehouse:before {
    content: '\e01d';
}
.socicon-pocket:before {
    content: '\e01e';
}
.socicon-mail:before {
    content: '\e01f';
}
.socicon-blogger:before {
    content: '\e020';
}
.socicon-technorati:before {
    content: '\e021';
}
.socicon-reddit:before {
    content: '\e022';
}
.socicon-dribbble:before {
    content: '\e023';
}
.socicon-stumbleupon:before {
    content: '\e024';
}
.socicon-digg:before {
    content: '\e025';
}
.socicon-envato:before {
    content: '\e026';
}
.socicon-behance:before {
    content: '\e027';
}
.socicon-delicious:before {
    content: '\e028';
}
.socicon-deviantart:before {
    content: '\e029';
}
.socicon-forrst:before {
    content: '\e02a';
}
.socicon-play:before {
    content: '\e02b';
}
.socicon-zerply:before {
    content: '\e02c';
}
.socicon-wikipedia:before {
    content: '\e02d';
}
.socicon-apple:before {
    content: '\e02e';
}
.socicon-flattr:before {
    content: '\e02f';
}
.socicon-github:before {
    content: '\e030';
}
.socicon-renren:before {
    content: '\e031';
}
.socicon-friendfeed:before {
    content: '\e032';
}
.socicon-newsvine:before {
    content: '\e033';
}
.socicon-identica:before {
    content: '\e034';
}
.socicon-bebo:before {
    content: '\e035';
}
.socicon-zynga:before {
    content: '\e036';
}
.socicon-steam:before {
    content: '\e037';
}
.socicon-xbox:before {
    content: '\e038';
}
.socicon-windows:before {
    content: '\e039';
}
.socicon-qq:before {
    content: '\e03a';
}
.socicon-douban:before {
    content: '\e03b';
}
.socicon-meetup:before {
    content: '\e03c';
}
.socicon-playstation:before {
    content: '\e03d';
}
.socicon-android:before {
    content: '\e03e';
}
.socicon-snapchat:before {
    content: '\e03f';
}
.socicon-twitter:before {
    content: '\e040';
}
.socicon-facebook:before {
    content: '\e041';
}
.socicon-googleplus:before {
    content: '\e042';
}
.socicon-pinterest:before {
    content: '\e043';
}
.socicon-foursquare:before {
    content: '\e044';
}
.socicon-yahoo:before {
    content: '\e045';
}
.socicon-skype:before {
    content: '\e046';
}
.socicon-yelp:before {
    content: '\e047';
}
.socicon-feedburner:before {
    content: '\e048';
}
.socicon-linkedin:before {
    content: '\e049';
}
.socicon-viadeo:before {
    content: '\e04a';
}
.socicon-xing:before {
    content: '\e04b';
}
.socicon-myspace:before {
    content: '\e04c';
}
.socicon-soundcloud:before {
    content: '\e04d';
}
.socicon-spotify:before {
    content: '\e04e';
}
.socicon-grooveshark:before {
    content: '\e04f';
}
.socicon-lastfm:before {
    content: '\e050';
}
.socicon-youtube:before {
    content: '\e051';
}
.socicon-vimeo:before {
    content: '\e052';
}
.socicon-dailymotion:before {
    content: '\e053';
}
.socicon-vine:before {
    content: '\e054';
}
.socicon-flickr:before {
    content: '\e055';
}
.socicon-500px:before {
    content: '\e056';
}
.socicon-wordpress:before {
    content: '\e058';
}
.socicon-tumblr:before {
    content: '\e059';
}
.socicon-twitch:before {
    content: '\e05a';
}
.socicon-8tracks:before {
    content: '\e05b';
}
.socicon-amazon:before {
    content: '\e05c';
}
.socicon-icq:before {
    content: '\e05d';
}
.socicon-smugmug:before {
    content: '\e05e';
}
.socicon-ravelry:before {
    content: '\e05f';
}
.socicon-weibo:before {
    content: '\e060';
}
.socicon-baidu:before {
    content: '\e061';
}
.socicon-angellist:before {
    content: '\e062';
}
.socicon-ebay:before {
    content: '\e063';
}
.socicon-imdb:before {
    content: '\e064';
}
.socicon-stayfriends:before {
    content: '\e065';
}
.socicon-residentadvisor:before {
    content: '\e066';
}
.socicon-google:before {
    content: '\e067';
}
.socicon-yandex:before {
    content: '\e068';
}
.socicon-sharethis:before {
    content: '\e069';
}
.socicon-bandcamp:before {
    content: '\e06a';
}
.socicon-itunes:before {
    content: '\e06b';
}
.socicon-deezer:before {
    content: '\e06c';
}
.socicon-telegram:before {
    content: '\e06e';
}
.socicon-openid:before {
    content: '\e06f';
}
.socicon-amplement:before {
    content: '\e070';
}
.socicon-viber:before {
    content: '\e071';
}
.socicon-zomato:before {
    content: '\e072';
}
.socicon-quora:before {
    content: '\e073';
}
.socicon-draugiem:before {
    content: '\e074';
}
.socicon-endomodo:before {
    content: '\e075';
}
.socicon-filmweb:before {
    content: '\e076';
}
.socicon-stackexchange:before {
    content: '\e077';
}
.socicon-wykop:before {
    content: '\e078';
}
.socicon-teamspeak:before {
    content: '\e079';
}
.socicon-teamviewer:before {
    content: '\e07a';
}
.socicon-ventrilo:before {
    content: '\e07b';
}
.socicon-younow:before {
    content: '\e07c';
}
.socicon-raidcall:before {
    content: '\e07d';
}
.socicon-mumble:before {
    content: '\e07e';
}
.socicon-medium:before {
    content: '\e06d';
}
.socicon-bebee:before {
    content: '\e07f';
}
.socicon-hitbox:before {
    content: '\e080';
}
.socicon-reverbnation:before {
    content: '\e081';
}
.socicon-formulr:before {
    content: '\e082';
}
.socicon-instagram:before {
    content: '\e057';
}
.socicon-battlenet:before {
    content: '\e083';
}